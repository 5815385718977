<template>
  <div>
    <component
      :is="vue_slider"
      v-model="range"
      :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :max="max_value"
      :min="min_value"
      :interval="interval"
      @drag-end="changeSlide"
      :tooltip="'none'"
    >
      <template v-slot:dot="{ value }">
        <div :class="getClasses(value)"></div>
      </template>

      <template v-slot:process="{ style, index }">
        <div class="vue-slider-process custom-class" :style="[style, getProgressStyle(style, index)]">
        </div>
      </template>
    </component>
    <div class="row mt-1">
      <div class="col-12" v-if="only_from">
        <p class="text-left m-0">{{$t('search.from')}}</p>
        <div class="position-relative">
          <component
            :is="cleave"            
            v-model="from" 
            :raw="true"
            :options="options.number"
            @blur="blurActionCleave('show_options_from')"
            @input="changeInput()"
            placeholder="10,000"
            class="form-control"
            ref="cleave-component-0"
            id="cleave-component-0-range"
          ></component>
          <div class="container-floating-numbers-options" v-if="show_options_from">
            <div v-for="option in options_numbers" :key="option" class="option-number" @click="selectModel(option, null)">
              {{ separatebycomma(option) }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex" v-else>
        <div class="col-6">
          <p class="text-left m-0">{{$t('search.from')}}</p>
          <div class="position-relative">
      
            <component 
              :is="cleave"
              v-model="from" 
              :raw="false"
              :options="options.number"
              placeholder="10,000"
              class="form-control"
              @blur="blurActionCleave('show_options_from')"
              @input="changeInput()"
              ref="cleave-component-0"
              id="cleave-component-0-range"
            ></component>
            <div class="container-floating-numbers-options" v-if="show_options_from">
              <div v-for="option in options_numbers" :key="option" class="option-number" @click="selectModel(option, 0)">
                {{ separatebycomma(option) }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <p class="text-left m-0">{{$t('search.to')}}</p>
          <div class="position-relative">
            <component 
              :is="cleave"
              v-model="to" 
              :raw="false"
              :options="options.number"
              placeholder="10,000"
              class="form-control"
              @blur="blurActionCleave('show_options_to')"
              @input="changeInput()"
              ref="cleave-component-1"
              id="cleave-component-1-range"
            ></component>
            <div class="container-floating-numbers-options" v-if="show_options_to">
              <div v-for="option in options_numbers.slice(1, options_numbers.length)" :key="option" class="option-number" @click="selectModel(option, 1)">
                {{ separatebycomma(option) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import { separatebycomma, trimNumber } from '@/libs/utils/formats';

export default {
  name: 'viewAvgSlider',
  props: {
    min_value: {
      type: Number,
      required: true
    },
    max_value: {
      type: Number,
      required: true
    },
    interval: {
      type: Number,
      required: true
    },
    init_range: {
      type: Array,
      required: false
    },
    slider: {
      type: Number,
      required: false,
    },
    identifier: {
      type: String,
      default: ''
    },
    only_from: {
      type: Boolean,
      default: false,
    },
    options_numbers: {
      type: Array,
      default: () => {
        return [10000, 50000, 100000, 500000, 1000000, 5000000, 10000000];
      }
    }
  },
  components: {
    BFormInput,
  },
  data () {
    return {
      separatebycomma,
      cleave: null,
      vue_slider: null,
      range: null,
      from: null,
      to: null,
      show_options_from: false,
      show_options_to: false,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
    }
  },
  async mounted() {
    this.vue_slider = (await import('vue-slider-component')).default;
    this.cleave = (await import('vue-cleave-component')).default;
  },
  created () {
    if (this.slider !== undefined) this.range = this.slider
    else if (this.init_range !== undefined) {
      this.range = this.init_range
      this.from = this.range[0]
      this.to = this.range[1]
    }
    setTimeout(() => {
      for (let index = 0; index < 2; index++) {
        if (this.$refs[`cleave-component-${index}`]) this.$refs[`cleave-component-${index}`].$el.addEventListener('focus', this.handleFocus);
      }
    }, 2000);
  },
  beforeDestroy() {
    for (let index = 0; index < 2; index++) {
      if (this.$refs[`cleave-component-${index}`]) this.$refs[`cleave-component-${index}`].$el.removeEventListener('focus', this.handleFocus);  
    }
  },
  methods: {
    handleFocus(input_focus) {
      if (input_focus.target.id === 'cleave-component-0-range') {
        this.show_options_from = true;
      } else {
        this.show_options_to = true;
      }
    },
    changeInput() {
      const from = typeof this.from !== 'number' ? trimNumber(this.from) : this.from
      if (!this.only_from) {
        const to = isNaN(this.to) ? trimNumber(this.to) : this.to
        this.range = [from, to]
      } else this.range = from;
    },
    blurActionCleave(model_options) {
      setTimeout(() => {
        this[model_options] = false;
        this.$emit('changeSlide', this.identifier, this.range);
      }, 200);
    },
    changeSlide() {
      if (!this.only_from) {
        this.from = this.range[0];
        this.to = this.range[1];
      } else this.from = this.range
      this.$emit('changeSlide', this.identifier, this.range)
    },
    getClasses(value) {
      if (value === this.min_value || value === this.max_value) return 'value-inactive'
      return 'value-active'
    },
    getProgressStyle(style) {
      if (this.range[0] !== this.min_value && this.range[1] !== this.max_value) {
        return {
          'left': style.left,
          'width': style.width,
          'background-color': '#7367f0'
        }
      } else if (this.range[0] === this.min_value && this.range[1] !== this.max_value) {
        const width = 100 - parseFloat(style.width)
        return {
          'left': style.width,
          'width': `${width}%`,
          'background-color': '#7367f0'
        }
      }

      const left = this.range[1] === this.max_value ? 0 : style.width;
      const width = this.range[0] === this.min_value ? style.width : style.left;
      return {
        left,
        width,
        'background-color': '#7367f0'
      }
    },
    blurActionInput(show_model) {
      setTimeout(() => {        
        this[show_model] = false;
      }, 200);
    },
    selectModel(value, index) {
      if (index !== null) this.range[index] = value;
      else this.range = value
      this.changeSlide();
    }
  },
}
</script>
<style lang="scss" scoped>
.container-floating-numbers-options {
  background-color: white;
  position: absolute;
  bottom: 0l;
  left: 0;
  box-shadow: 0 5px 25px rgba(34, 41, 47, 0.2);
  font-family: 'avenir-medium';

  .option-number {
    padding: 1em;

    &:hover {
      background-color: #EFF3F6;
    }
  }
}
</style>
